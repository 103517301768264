import { useRef } from "react"
import { useEffect } from "react"
import { useState } from "react"
import { Button, Card, Form, Spinner, Row, Col, InputGroup, FormGroup, Table, Tabs, Tab } from "react-bootstrap"
import { AdminProduct, CustomAdminProduct, Product } from "../../Components/ProductComponents"
import { sendAdminLoginReq } from "../../Services/AdminService"
import { changeShipping, getOrders } from "../../Services/OrderService"
import { addProduct, getProducts, removeProduct } from "../../Services/ProductService"

const MistAdmin = () => {
    const [creds, setCreds] = useState(null)

    return (
        <div>
            <h1>Hallinnointisivu</h1>
            { creds ? <AdminPanel creds={creds} /> : <SignIn setData={setCreds} /> }
            <p>Kirjaudut automaattisesti ulos, kun poistut sivulta tai lataat sivun uudelleen.</p>
        </div>
    )
}

const SignIn = ({ setData }) => {
    const [loading, setLoading] = useState(false)

    const uname = useRef(null)
    const pass = useRef(null)
    
    const log = (e) => {
        e.preventDefault()
        setLoading(true)
        sendAdminLoginReq(uname.current.value, pass.current.value)
            .then (res => {
                setLoading(false)
                setData(res.data)
            })
            .catch (err => {
                alert('err')
                setLoading(false)
            })
    }

    if (loading) {
        return <Spinner style={{ marginTop: '5px' }} animation="border" variant="primary" />
    }

    return (
        <div>
            <Form onSubmit={log}>
                <Card style={{ maxWidth: '500px' }}>
                    <Card.Header>Kirjaudu hallinnointitunnuksilla</Card.Header>
                    <Card.Body> 
                        <Form.Group className="mb-3" controlId='uname'>
                            <Form.Label>Käyttäjätunnus</Form.Label>
                            <Form.Control ref={uname} placeholder='Syötä käyttäjätunnus.' />
                        </Form.Group>
                    
                        <Form.Group className="mb-3" controlId="pword">
                            <Form.Label>Salasana</Form.Label>
                            <Form.Control ref={pass} type="password" placeholder="Syötä salasana" />
                        </Form.Group>

                        <Button variant='primary' type='submit'>Kirjaudu</Button>
                    </Card.Body>
                </Card>
            </Form>
        </div>
    )
}

const AdminPanel = ({ creds }) => {

    const [products, setProducts] = useState(null)
    const [product, setProduct] = useState(null)

    const [createMode, setCreate] = useState(false)
    const [viewOrders, setViewOrders] = useState(false)

    useEffect(() => {
        getProducts()
            .then(res => setProducts(res.data))
            .catch(err => alert('error fetching products'))
    }, [])
    
    const findProductById = (id) => {
        setProduct(products.filter(product => product.id === id)[0])
    }

    const removeById = (id) => removeProduct(id, creds.token)
        .then(() => {
            // update products
            setProducts(products.filter(prod => prod.id !== id))
            alert('Poisto onnistui!')
            setProduct(null)
        })
        .catch(err => alert('error'))


    if (!products) {
        return <p><b>Ladataan tuotteita...</b></p>
    }

    if (product) {
        return (
            <div>
                <Button onClick={() => setProduct(null)} style={{ marginBottom: '5px' }} variant='secondary'>Valitse toinen</Button>
                <CustomAdminProduct product={product}>
                    <Card.Link onClick={() => removeById(product.id)} href='#'>Posta tuote</Card.Link>
                </CustomAdminProduct>

            </div>
        )
    }

    if (createMode) {
        return <NewProduct creds={creds} back={() => setCreate(false)} />
    }

    if (viewOrders) {
        return <OrderList creds={creds} back={() => setViewOrders(false)} />
    }

    return (
        <div>
            <h3>Tuotteet</h3>
            <Row>
                {products.map(product => <Col key={product.id}><AdminProduct findById={(id) => findProductById(id)} product={product} /></Col>)}
            </Row>
            <Button onClick={() => setCreate(true)} variant="primary">Luo uusi tuote</Button>
            <Button style={{ marginLeft: '5px' }} onClick={() => setViewOrders(true)} variant='secondary'>Asiakastiedot</Button>
        </div>
    )
}

const NewProduct = ({ back, creds }) => {
    const [price, setPrice] = useState('')
    const [name, setName] = useState('')
    
    const [photos, setPhotos] = useState([])
    const [desc, setDesc] = useState('')

    const isValid = (price !== '' && name !== '' && photos.length !== 0 && desc !== '')
    const formRef = useRef(null)

    const sendReq = (e) => {
        e.preventDefault()


        const data = new FormData(e.target)
        addProduct(data, creds.token)
            .then(res => {
                alert('Tuote luotu onnistuneesti!')
                back()
            })
            .catch(err => {
                console.error(err)
                alert('Virhe tapahtui tuotetta luodessa!')
            })
    }
    
    return (
        <div>
            <Card style={{ maxWidth: '525px', marginBottom: '5px' }} as='form' ref={formRef} onSubmit={(e) => sendReq(e)}>
                <Card.Header>Luo uusi tuote</Card.Header>
                <Card.Body>
                    <Form.Group className="mb-3">
                        <Form.Label>Tuotteen nimi</Form.Label>
                        <Form.Control name="name" value={name} onChange={(e) => setName(e.target.value)} placeholder="Esim. SumuLaite 112" />
                    </Form.Group>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">
                            € <Form.Control name="price" style={{ marginLeft: '7.5px' }} value={price} onChange={(e) => setPrice(e.target.value)} type="number" placeholder="Tuotteen hinta" aria-label="Hitna" aria-describedby="basic-addon1" />
                        </InputGroup.Text>
                    </InputGroup>
                    <FormGroup className="mb-3">
                        <Form.Label>Tuotteen kuvat</Form.Label>
                        <Form.Control name="photos" type='file' multiple onChange={(e) => setPhotos(e.target.files)} />
                    </FormGroup>
                    <FormGroup className="mb-3">
                        <Form.Label>Tuotteen kuvaus</Form.Label>
                        <Form.Control name="desc" value={desc} onChange={(e) => setDesc(e.target.value)} as="textarea" placeholder="Esim. Tuote on erinomainen. Ilman sitä ei voi nauttia elämästä!" />
                    </FormGroup>
                    <Button type='submit' variant='primary' disabled={!isValid}>Luo tuote</Button>
                </Card.Body>
            </Card>
            <Button variant='secondary' onClick={back}>Palaa takaisin</Button>
        </div>
    )
}

const OrderList = ({ back, creds }) => {
    const [orders, setOrders] = useState(null)
    const [selectedOrder, setOrder] = useState(null)

    // isShipped & setShipped are states to relay 
    // for Order to refresh OrderList after changed
    // shipping state.

    const [hasChanged, setChanged] = useState(true)


    useEffect(() => {
        if (hasChanged === true)
        {
            getOrders(creds.token)
            .then(res => {
                setOrders(res.data)
                setChanged(false)
            })
            .catch(err => alert('Virhe ostotapahtumia ladatessa.'))
        }

    }, [hasChanged])

    const orderRef = useRef(null)
    

    if (!orders) {
        return <h5>Ladataan....</h5>
    }

    if (selectedOrder) {
        return <Order creds={creds} setChanged={setChanged} orderID={selectedOrder.id} back={() => setOrder(null)} />
    }

    const unshippedOrders = orders.filter(order => order.isShipped === 0)
    const shippedOrders = orders.filter(order => order.isShipped === 1)

    return (
        <div>
            

                <Tabs
                    defaultActiveKey='unshipped'
                    id="order-list"
                    className="mb-3"
                >
                    <Tab eventKey="unshipped" title="Postittamattomat">
                        <h5>Postittamattomat tilaukset</h5>
                        <OrderTable setOrder={setOrder} orders={unshippedOrders} />
                    </Tab>
                    <Tab eventKey="shipped" title="Postitetut">
                        <h5>Postitetut tilaukset</h5>
                        <OrderTable setOrder={setOrder} orders={shippedOrders} />
                    </Tab>
                    
                </Tabs>

            <br />
            <Button onClick={back} variant='secondary'>Takaisin</Button>
            
        </div>
    )

}

const OrderTable = ({ orders, setOrder }) => {
    if (!orders || orders.length === 0) {
        return <p><b>Ei tuloksia.</b></p>
    }

    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    <td>ID</td>
                    <th>Email</th>
                    <th>Osoite</th>
                    <th>Summa</th>
                    <th>Valuutta</th>
                    <th>Onko postitettu</th>
                    <th>Tuotteet</th>
                </tr>
            </thead>
            <tbody>
                {orders.map(order => {
                    return (
                        <tr key={order.id}>
                            <td>{order.id}</td>
                            <td>{order.email}</td>
                            <td>{order.address}</td>
                            <td>{order.currency_value}</td>
                            <td>{order.currency_code}</td>
                            <td>{order.isShipped === 0 ? 'Ei' : 'Kyllä'}</td>
                            <td><Button variant={order.isShipped === 0 ? 'success' : 'secondary'} size="sm" onClick={() => setOrder(order)}>Katso</Button></td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>
    )
}
 
const Order = ({ orderID, back, creds, setChanged }) => {
    const [order, setOrder] = useState(null)
    const [products, setProducts] = useState(null)

    useEffect(() => {
        const getData = async () => {
            const orderRes = await getOrders(creds.token)

            const orders = orderRes.data
            const order = orders.find(order => order.id.toString() === orderID.toString())
            setOrder(order)

            try {
                const productRes = await getProducts()
                setProducts(productRes.data)
            } catch (e) {
                alert('Virhe tuotteiden lataamisessa.')
            }
        }
        
        getData()

    }, [])

    if (!order || !products) {
        return <Spinner animation='border' />
    }
    

    const ship = () => {
        console.log(order)
        const value = order.isShipped === 0 ? 1 : 0

        changeShipping(creds.token, order.id, value)
            .then(res => {
                setOrder(res.data)
                setChanged(true)
                back()                
            })
            .catch(e => alert('Virhe postitetuksi merkitsemisessä'))
    }

    return (
        <div>
            <Card>
                <Card.Header>Postitustiedot</Card.Header>
                <Card.Body>
                    <b>Postitusosoite: </b> {order.address}
                    <hr />
                    {
                        products ? <OrderProducts total={order.currency_value} totalCurrencyCode={order.currency_code} allProducts={products} productsToShow={JSON.parse(order.productList)} /> : <Spinner animation="grow" />
                    }
                    
                    { order.isShipped === 0 
                        ? <Button onClick={ship} style={{ marginTop: '5px' }} variant="success">Merkitse postitetuksi</Button> 
                        : <Button onClick={ship} style={{ marginTop: '5px' }} variant="danger">Merkitse postittamattomaksi</Button>
                    }
                    
                </Card.Body>
            </Card>            
            <br />
            <Button variant="secondary" onClick={back}>Takaisin</Button>
            <hr />
        </div>
    )
}

const OrderProducts = ({ allProducts, productsToShow, total, totalCurrencyCode }) => {
    console.log('pts', productsToShow)

    let products = allProducts.filter(prod => productsToShow.includes(prod.id.toString()))

    console.log(typeof productsToShow)

    let sum = 0
    for (let i = 0; i < products.length; i++) {
        let product = products[i]
        console.log(product)
        sum += product.productPrice
    }

    return (
        <div>
            <p><b>Tuotteet:</b> <i>{products.map(prod => prod.productName).join(', ')}</i></p>
            <p>
                Tuotteiden yhteenlaskettu hinta: <b>{sum.toString()} EUR</b>
                <br />
                Joista maksettu: <b>{total} {totalCurrencyCode}</b>
            </p>

        </div>
    )
}

export default MistAdmin