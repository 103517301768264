
import { ProductList } from "../Components/ProductComponents"

const ProductListSite = ({ changeAlerts }) => {
    return (
        <div>
            <h1>Product list</h1>
            <p>Here are our all products. Let's shop!</p>
            <ProductList changeAlerts={changeAlerts} />
        </div>
    )
}

export default ProductListSite