import { NavLink } from "react-router-dom"
import { Product, ProductList, ProductsShowcase } from "../Components/ProductComponents"
import RouterLink from "../Components/RouterLink"

const Index = () => {
    return (
        <div style={{ marginTop: '10px' }}>
            <h1>Welcome!</h1>
            <p>Welcome to our shop. From our shop you will find the <b>best, awesome air fresheners. Navigate to the <RouterLink to='/products'>product section</RouterLink> for more!</b></p>

            <ProductsShowcase />
        </div>
    )
}

export default Index