const { default: RouterLink } = require("../Components/RouterLink")

const NotFound = () => {
    return (
        <div>
            <h1>404 - Oh no!</h1>
            <p>We couldn't find your page. But, did you know that we have awesome products in sale. <RouterLink to='/'>Go to check!</RouterLink></p>
        </div>
    )
}

export default NotFound