import {  Container, Navbar, Nav } from "react-bootstrap"
import { Outlet } from "react-router-dom"
import { ErrorAlert, SuccessAlert } from "./Components/Alerts"
import RouterLink from "./Components/RouterLink"

const Layout = ({ alerts }) => {
    const { success, fail } = alerts

    return (
        <div>

            <NavbarComp />

            <Container>
                    <div style={{ marginTop: '5px' }}>
                    <SuccessAlert content={success.value} />
                    <ErrorAlert content={fail.value} />
                    </div>
                <Outlet />
            </Container>
        </div>
    )
}

const NavbarComp = () => (
    <Navbar bg='dark' variant="dark" expand="lg">
        <Container>
            <Navbar.Brand as={RouterLink} to='/'>TheMistShop</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic" />
            <Navbar.Collapse id="basic">
                <Nav className="me-auto">
                    <Nav.Link as={RouterLink} to='/'>
                        Home
                    </Nav.Link>
                    <Nav.Link as={RouterLink} to='/products'>
                        Products
                    </Nav.Link>
                    <Nav.Link as={RouterLink} to='/cart'>
                        Cart 
                    </Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Container>
    </Navbar>
)

export default Layout