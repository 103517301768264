import axios from "axios";

import config from './serviceConfig'
const BASE_URL = config.BASE_URL
const PRODUCT_API = BASE_URL + 'api/products/'

const getProducts = async () => {
    const products = await axios.get(PRODUCT_API)
    return products
}

const getProduct = async (id) => {
    const URL = PRODUCT_API + id
    return await axios.get(URL)
}

const getImageURL = ( filename ) => {
    const URL = BASE_URL + 'images/'
    return URL + filename
}

// removeproduct needs also auth token
const removeProduct = async (id, token) => {
    const URL = PRODUCT_API + id
    await axios.delete(URL, {
        headers: {
            authorization: token
        }
    })
}

const addProduct = async (data, token) => {
    await axios.post(PRODUCT_API, data, {
        headers: {
            "Content-Type": "multipart/form-data",
            authorization: token
        }
    })
}

export { 
    getProducts,
    getProduct,
    getImageURL,
    removeProduct,
    addProduct
}