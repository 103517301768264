import 'bootstrap/dist/css/bootstrap.min.css';
import { useState } from 'react';

import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./Layout";
import MistAdmin from './SiteComponents/Admin/mistAdmin';
import Index from "./SiteComponents/Index";
import ProductSite from './SiteComponents/ProductSite';
import ProductListSite from './SiteComponents/ProductListSite';
import { Cart } from './SiteComponents/Cart';
import NotFound from './SiteComponents/404';

const App = () => {
  const [successMsg, setSuccess] = useState(null)
  const [failMsg, setFail] = useState(null)
  const alerts = {
    success: {
      value: successMsg
    },
    fail: {
      value: failMsg
    }
  }  

  const changeAlerts = {
    success: (message) => {
      setSuccess(message)
      setTimeout(() => {
        setSuccess(null)
      }, 7000)
    },
    fail: (message) => {
      setFail(message)
      setTimeout(() => {
        setFail(null)
      }, 7000)
    }
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout alerts={alerts} />}>
          <Route index element={<Index />} />

          <Route path='products'>
            <Route index element={<ProductListSite changeAlerts={changeAlerts} />} />
            <Route path=':id' element={<ProductSite changeAlerts={changeAlerts} />} />
          </Route>

          <Route path='cart' element={<Cart changeAlerts={changeAlerts} />} />

          <Route path='/mist-admin' element={<MistAdmin />} />
          <Route path='*' element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
    
  )
}

export default App;
