import { useEffect, useState } from "react"
import { Button, Card, Carousel, CarouselItem, Col, Placeholder, Row } from "react-bootstrap"
import { getImageURL, getProduct, getProducts } from "../Services/ProductService"
import RouterLink from "./RouterLink"

import placeholder from '../Images/placeholder.webp'
import { cartAdd } from "../SiteComponents/Cart"

const getCart = () => JSON.parse(window.localStorage.getItem('cartItems'))
const addAndReturn = (itemIdStr) => {
    const currentCart = getCart()
    const newCart = JSON.stringify([...currentCart, itemIdStr.toString()])
 
    window.localStorage.setItem('cartItems', newCart)
    return newCart
}

const Product = ({ product }) => {

    var { imgUrls, productName, productPrice, productDesc } = product
    imgUrls = JSON.parse(imgUrls)

    const [cart, setCart] = useState(getCart())
    let itemFound = cart ? cart.includes(product.id.toString()) : false

    const removeFromCart = () => {
        
        let cartData = 
            cart ? JSON.parse(window.localStorage.getItem('cartItems')) : []

    
        const newCart = JSON.stringify(cartData.filter(itemID => product.id.toString() !== itemID.toString()))
        window.localStorage.setItem('cartItems', newCart)
        setCart(newCart)
    }

    
    const addToCart = () => {

        let cartData = null
        if (cart) {
            cartData = getCart()
        } else {
            cartData = []        
        }

        cartData.push(product.id.toString())
        window.localStorage.setItem('cartItems', JSON.stringify(cartData))
        setCart(getCart())
    }
    

    return (
        <Card style={{ width: '22rem', marginBottom: '30px' }}>
            <Card.Img variant='top' src={getImageURL(imgUrls[0])} />
            <Card.Body>

                <Card.Title>{productName}</Card.Title>

                <Card.Subtitle className="mb-2 text-muted">{productPrice}€</Card.Subtitle>
                <Card.Text style={{ maxHeight: '3em', textOverflow: 'ellipsis', display: 'block', wordWrap: 'break-word' }}>
                    {productDesc}
                </Card.Text>
                <RouterLink to={`/products/${product.id}`}>
                    <Button variant='primary'>View product</Button>
                </RouterLink>
                {itemFound 
                    ? <Button style={{ marginLeft: '5px' }} onClick={removeFromCart} variant='danger'>Remove from cart</Button>
                    : <Button style={{ marginLeft: '5px' }} onClick={addToCart} variant='secondary'>Add to cart</Button>
                }
            </Card.Body>
        </Card>
    )
}

const ProductList = ({ changeAlerts }) => {
    const [products, setProducts] = useState(null)

    useEffect(() => {
        getProducts()
            .then(res => setProducts(res.data))
            .catch(err => changeAlerts.fail('Something went wrong!'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (!products) {
        return <PlaceholderProducts />
    }

    return (
        <Row>
            {products.map(product => <Col key={product.id}><Product product={product} /></Col>)}
        </Row>
    )

}

const AdminProduct = ({ product, findById }) => {
    let { imgUrls, productName, productPrice, productDesc } = product
    
    imgUrls = JSON.parse(imgUrls)
        return (
        <Card style={{ width: '18rem', marginBottom: '30px' }}>
            <Card.Img variant='top' src={getImageURL(imgUrls[0])} />
            <Card.Body>

                <Card.Title>{productName}</Card.Title>

                <Card.Subtitle className="mb-2 text-muted">{productPrice}€</Card.Subtitle>
                <Card.Text style={{ maxHeight: '3em', textOverflow: 'ellipsis', display: 'block', wordWrap: 'break-word' }}>
                    {productDesc}
                </Card.Text>
                <Button onClick={() => findById(product.id)}>Valitse</Button>
            </Card.Body>
        </Card>
    )
}

const CustomAdminProduct = ({ product, findById, children }) => {
    let { imgUrls, productName, productPrice, productDesc } = product
    
    imgUrls = JSON.parse(imgUrls)
        return (
        <Card style={{ width: '18rem', marginBottom: '30px' }}>
            <Card.Img variant='top' src={getImageURL(imgUrls[0])} />
            <Card.Body>

                <Card.Title>{productName}</Card.Title>

                <Card.Subtitle className="mb-2 text-muted">{productPrice}€</Card.Subtitle>
                <Card.Text style={{ maxHeight: '3em', textOverflow: 'ellipsis', display: 'block', wordWrap: 'break-word' }}>
                    {productDesc}
                </Card.Text>
                {children}
            </Card.Body>
        </Card>
    )
}

const ProductsShowcase = () => {

    const [products, setProducts] = useState(null)

    useEffect(() => {
        getProducts()
            .then(res => setProducts(res.data.reverse()))
            .catch(err => alert('alert'))
    }, [])

    if (!products) {
        return (
            <PlaceholderProducts />
        )
    }

    const prodsToShow = products.slice(0, 3)

    return (
        <Row>
            {prodsToShow.map(prod => <Col key={prod.id}><Product product={prod} /></Col>)}
        </Row>
    )

}

const PlaceholderProduct = () => (
    <Card style={{ width: '22em', marginBottom: '30px'}}>
        <Card.Img style={{ height: '20rem' }} variant="top" src={placeholder} />
        <Card.Body>
            <Placeholder as={Card.Title} animation="glow">
                <Placeholder xs={8} />
            </Placeholder>
            <Placeholder as={Card.Title} animation="glow">
                <Placeholder xs={2} />
            </Placeholder>
        </Card.Body>
    </Card>
)

const PlaceholderProducts = () => (
    <Row>
        <Col><PlaceholderProduct /></Col>
        <Col><PlaceholderProduct /></Col>
        <Col><PlaceholderProduct /></Col>
    </Row>
)

const ProductView = ({ id, changeAlerts }) => {
    const [product, setProduct] = useState(null)
    const [inCart, setInCard] = useState(null)
     
    useEffect(() => {
        getProduct(id)
            .then(res => setProduct(res.data))
            .catch(err => {
                console.log(err)
                if (err.response.status === 404) {
                    changeAlerts.fail("We couldn't find this product! The link may be broken or the product is not available now.")
                } else {
                    changeAlerts.fail("😵‍💫 Something happened! But this isn't your fault. Please try again.")
                }
            })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    if (!product) {
        return null
    }

    let cartStorage = getCart()
    cartStorage = cartStorage ? cartStorage : []
    
    const doesInclude = cartStorage.includes(product.id.toString())
    if (inCart !== doesInclude) {
        setInCard(doesInclude)
    }


    const filenames = JSON.parse(product.imgUrls)
    const addProductToCart = () => {        
        cartAdd(product.id.toString())
        setInCard(getCart().includes(product.id.toString()))
        changeAlerts.success(`Added ${product.productName} to your shipping cart!`)
    }

    const removeItem = () => {
        let newCartStorage = getCart()
        newCartStorage = newCartStorage ? newCartStorage : []

        newCartStorage = newCartStorage.filter(id => id.toString() !== product.id.toString())
        window.localStorage.setItem('cartItems', JSON.stringify(newCartStorage))
        setInCard(false)
    }

    return (
        <div>
            <h1 align='center'>{product.productName}</h1>
            <Card>
                <Carousel variant='light'>
                    {filenames.map((filename, index) => {
                        return (
                            <CarouselItem key={index} style={{ backgroundColor: 'gray' }}>
                                <img className="d-block" style={{ maxWidth: '500px', margin: 'auto' }} src={getImageURL(filename)} alt='Product' />
                            </CarouselItem>
                        )
                    })}

                </Carousel>
                <Card.Body>
                    <Card.Title>What is <b>{product.productName}?</b></Card.Title>
                    <Card.Subtitle style={{ marginBottom: '10px' }}><b>{product.productPrice}€</b></Card.Subtitle>
                    {product.productDesc}

                    <div style={{ marginTop: '10px' }}>
                        {
                            !inCart 
                                ? <Button variant='primary' onClick={addProductToCart}>Add to cart</Button>
                                : <Button variant='danger' onClick={removeItem}>Remove from cart</Button>
                        }
                        
                    </div>
                </Card.Body>

            </Card>
        </div>
    )
}

export { Product, ProductList, AdminProduct, CustomAdminProduct, ProductsShowcase, ProductView }