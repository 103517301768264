import axios from "axios"
import config from './serviceConfig'

const ORDER_URL = config.BASE_URL + 'api/orders'

const getOrders = async (auth) => {
    const orderResponse = await axios.get(ORDER_URL, { headers: {
        authorization: auth
    } })
    return orderResponse
}

const newOrder = async (capture_id, email, address, productList) => {
    const orderResponse = await axios.post(ORDER_URL, { capture_id, email, address, productList })
    return orderResponse
}

const changeShipping = async (auth, id, value) => {
    console.log(value)

    const URL = ORDER_URL + `/${id.toString()}/isShipped`
    const req = await axios.put(URL, {
        value: value
    }, { headers: {
        authorization: auth
    }})

    return req
}

export { newOrder, getOrders, changeShipping }