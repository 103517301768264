import { Link } from "react-router-dom"

const RouterLink = (props) => {
    return (
        <Link {...props} style={{ textDecoration: 'none' }}>
            {props.children}
        </Link>
    )
}

export default RouterLink