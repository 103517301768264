import { useParams } from "react-router-dom"
import { ProductView } from "../Components/ProductComponents"

const ProductSite = ({ changeAlerts }) => {
    const { id } = useParams()

    return (
        <div>
            <ProductView id={id} changeAlerts={changeAlerts} />
        </div>
    )
}

export default ProductSite