import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js"
import axios from "axios"
import { useEffect } from "react"
import { useState } from "react"
import { Card, Spinner, Table, Button } from "react-bootstrap"

import RouterLink from "../Components/RouterLink"
import { newOrder } from "../Services/OrderService"
import { getProduct, getProducts } from "../Services/ProductService"
import config from "../Services/serviceConfig"


const Cart = ({ changeAlerts }) => {

    let storageData = JSON.parse(window.localStorage.getItem('cartItems')) 
    const [cartItems, setItems] = useState(null)

    const getTotal = () => {
        let total = 0
        for (let i = 0; i < cartItems.length; i++) {
            let price = cartItems[i].productPrice
            total += price
        }
        
        return total
    }

    const removeFromCart = (id) => {
        // set state with newItems + update also localStorage

        const newItems = cartItems.filter(item => item.id !== id)
        setItems(newItems)


        const localStorageArray = newItems.map(item => item.id.toString())
        const strArray = JSON.stringify(localStorageArray)


        window.localStorage.setItem('cartItems', strArray)
    }

    useEffect(() => {
        if (storageData)
            getProducts()
                .then(res => {
                    const data = res.data
                    const filteredItems = data.filter(data => {
                        return storageData.includes(data.id.toString())
                    })

                    // if theres no items in cart then show no items
                    // warning instead of empty table

                    if (filteredItems.length === 0)
                        setItems(null)
                    else
                        setItems(filteredItems)


                    setLoading(false)
                })
                .catch(err => {
                    alert('err')
                    console.error(err)
                })
        else
            setLoading(false)
    }, [])

    const [loading, setLoading] = useState(true)

    return (
        <div>

            <h1>Shopping cart</h1>
            {loading ? <WaiterSpinner /> : null}


            {cartItems ? <CartItemList removeItem={removeFromCart} getTotal={getTotal} loading={loading} items={cartItems} /> : <p style={{ display: loading ? 'none' : '' }}>You don't have items in your cart. <RouterLink to='/products'>Let's change it!</RouterLink></p>}
            <PaymentView setItems={setItems} alerts={changeAlerts} getTotal={getTotal} loading={loading} cartItems={cartItems} />


            
        </div>
    )
}

const PaymentView = ({ loading, cartItems, getTotal, alerts, setItems }) => {
    

    if (!loading && !cartItems) {
        return null
    }

    const onOrderCreation = (data, actions) => {
        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        value: getTotal(),
                        currency: 'EUR'
                    },
                },
            ],
        })
    }



    const purchaseApproved = (data, actions) => {
        console.log(data)
        return actions.order.capture().then(async (details) => {
            const unit = details.purchase_units[0]
            console.log(
                'unit',
                unit
            )

            const capture_id = unit.payments.captures[0].id
            const email = details.purchase_units[0].payee.email_address

            // join address & name to one string
            const { shipping } = unit
            const address = shipping.name.full_name + ' ' + Object.values(shipping.address).join(' ')

            const currentCart = window.localStorage.getItem('cartItems')

            const order = await newOrder(capture_id, email, address, currentCart)
            console.log(order)

            window.localStorage.removeItem('cartItems')
            setItems(null)
            alerts.success('Thank you for your purchase.')

        })
    }

    const buttonRefs = {
        createOrder: onOrderCreation,
        onApprove: purchaseApproved
    }

    return (
        <div>
            <h2 style={{ display: loading ? 'none' : '', marginBottom: '10px' }}>Pay with</h2>
            <PayPalScriptProvider options={{ "client-id": config.paypalClientID, currency: "EUR", intent: "capture" }}>
                <Buttons refs={buttonRefs} loading={loading} />
            </PayPalScriptProvider>
        </div>

    )
}

const Buttons = ({ loading, refs }) => {
    const [{ isPending }] = usePayPalScriptReducer()

    if (loading) {
        return null
    } else if (isPending) {
        return <WaiterSpinner />
    }

    return (
        <div>
            <PayPalButtons {...refs} />
        </div>
    )
}

const WaiterSpinner = () => {
    return <Spinner animation="border" variant="primary" />
}

const CartItemList = ({ items, loading, getTotal, removeItem }) => {
    if (loading) {
        return null
    }

    return (
        <Card body style={{ marginBottom: '10px' }}>
            <Table bordered striped hover>
                <thead>
                    <tr>
                        <th>Product name</th>
                        <th>Product price</th>
                        <th>Product amount</th>
                    </tr>
                </thead>
                <tbody>
                    {items.map(item => {
                        return (
                            <tr key={item.id}>
                                <th>{item.productName} <Button onClick={(e) => removeItem(item.id)} style={{ marginLeft: '5px' }} size="sm" variant='outline-danger'>Remove</Button></th>
                                <th>{item.productPrice}€</th>
                                <th>1</th>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>

            <Card.Title>Total: {getTotal()}€</Card.Title>
        </Card>
    )
}

const cartAdd = (id) => {
    // parse cart data from JSON, append, load into JSON.
    let cardData = window.localStorage.getItem('cartItems')
    cardData = cardData ? JSON.parse(cardData) : []

    cardData = [...cardData, id]
    cardData = JSON.stringify(cardData)

    window.localStorage.setItem('cartItems', cardData)
}

export { Cart, cartAdd }